import { Routes, Route } from 'react-router-dom'
import {
  QueryClientProvider,
  QueryClient
} from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import { AppContextProvider } from '@context/store';
// import ErrorBoundary from '@components/ErrorBoundary';

import Base from '@components/layouts/Base';
import Keywords from '@containers/keywords';
import Keyword from '@containers/keywords/[id]';

import './App.scss';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
      suspense: false,
    },
  },
});

function App() {
  return (
    <AppContextProvider>
      <QueryClientProvider client={queryClient}>
        {/* <ErrorBoundary> */}
          <Routes>
            <Route path="/" element={<Base />}>
              <Route index element={<Keywords />} />
              <Route path=":slug" element={<Keyword />} />
            </Route>
            <Route path="*" element={<p>There's nothing here: 404!</p>} />
          </Routes>
        {/* </ErrorBoundary> */}
        <ReactQueryDevtools initialIsOpen />
      </QueryClientProvider>
    </AppContextProvider>
  );
}

export default App;
